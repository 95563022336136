import { input } from "@angular/core";
import { createActionGroup, props } from "@ngrx/store";
import { IAddress } from "../../models/address";
import { IPlaceAutoComplete } from "../../models/placeAutoComplete";
import { IGeocode } from "../../models/gecode";

export const AddressAutoCompleteActions = createActionGroup(
    {
        source: 'Address Form',
        events: {
            'Address AutoComplete': props<{searchTerm:string}>(),
            'Address GeoCode': props<{index:number, geocode:{input:string, type:string}}>(),
            'Address GeoCodes': props<{gecodes: IGeocode[]}>(),
            'Employees Address GeoCodes': props<{gecodes: IGeocode[]}>(),
            'Sites Address GeoCodes': props<{gecodes: IGeocode[]}>()
        }
    }
)

export const AddressAutoCompleteApiActions = createActionGroup({
    source: 'Google Place API',
    events:{
        'Address AutoCompleteSuccess': props<{placeAutoComplete: IPlaceAutoComplete[]}>(),
        'Address AutoCompleteFail': props<{message:string}>(),
        'Address GeoCodeSuccess': props<{address:IAddress[]}>(),
        'Address GeoCodeFail': props<{message:string}>(),
        'Addresses GeoCodeSuccess': props<{addresses:Map<string, IAddress[]>}>(),
        'Addresses GeoCodeFail': props<{message:string}>(),
        'Employee Addresses GeoCodeSuccess': props<{addresses:Map<string, IAddress[]>}>(),
        'Employee Addresses GeoCodeFail': props<{message:string}>(),
        'Site Addresses GeoCodeSuccess': props<{addresses:Map<string, IAddress[]>}>(),
        'Site Addresses GeoCodeFail': props<{message:string}>()
        }
})